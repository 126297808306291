import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Add state for confirm password
  // States for document acceptance
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);
  const [waiverAccepted, setWaiverAccepted] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.'); // Use toast to notify user
      return; // Stop the function if passwords do not match
    }
      // Ensure all legal documents are accepted
      if (!privacyPolicyAccepted || !termsOfUseAccepted || !waiverAccepted) {
        toast.error('Please accept all the terms and policies to proceed.');
        return;
      }
    try {
      const response = await axios.post('/register', { email, password, privacyPolicyAccepted, termsOfUseAccepted, 
        waiverAccepted });
      if (response.status === 201) {
        toast.success('Registration successful! Now please login');
        // Assuming server-side logic sends a verification email and responds with 201 upon successful registration
        navigate('/login'); // Optionally navigate to login page or keep user on the same page to wait for verification
      } else {
        toast.error('Registration failed. Please try again.'); // Use toast for consistent error messaging
      }
    } catch (err) {
      toast.error('Registration failed. Please try again.'); // Handling unexpected errors
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {/* Consolidated legal documents and checkboxes */}
        <div className="compliance-documents">
          <div>
            <label>
              <input
                type="checkbox"
                checked={privacyPolicyAccepted}
                onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
              />
              I agree to the <a href="/documents/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={termsOfUseAccepted}
                onChange={(e) => setTermsOfUseAccepted(e.target.checked)}
              />
              I agree to the <a href="/documents/terms-of-use.html" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={waiverAccepted}
                onChange={(e) => setWaiverAccepted(e.target.checked)}
              />
              I have read and accept the <a href="/documents/waiver-of-liability.html" target="_blank" rel="noopener noreferrer">Waiver of Liability</a>.
            </label>
          </div>
        </div>
        
        <button type="submit">Register</button>
      </form>
      <div style={{marginTop: "20px"}}>
        Already have an account? <a href="/login">Login here</a>.
      </div>
    </div>
  );
}

export default Register;
