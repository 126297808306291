import  { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Make sure to import Link from 'react-router-dom'
import { useAuth } from './AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', { email, password }, { withCredentials: true });
      console.log(response);
      if (response.status === 200) {
        setIsAuthenticated(true);
        setUser(response.data.user);
        if (response.data.user.needsToAcceptTerms) {
          console.log(response.data.user.needsToAcceptTerms)
          navigate('/accept-terms');
          console.log('Navigating to accept-terms:');
        } else
        navigate('/'); // Navigate to home or dashboard upon successful login

      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
      {error && <p>{error}</p>}
      <div style={{marginTop: "20px"}}>
        Don't have an account? <Link to="/register">Register here</Link>.
      </div>
    </div>
  );
}

export default Login;
