import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

import Home from './Home';
import Profile from './Profile';
import Navbar from './Navbar';
import Login from './Login';
import Register from './Register';
import AcceptTerms from './AcceptTerms';
import About from './About';
import Workouts from './Workouts';
import { AuthProvider, useAuth } from './AuthContext';
import './app.css';

function AuthenticatedRoutes() {
  const { isAuthenticated } = useAuth();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
      <Route path="/workouts" element={isAuthenticated ? <Workouts /> : <Navigate to="/login" />} />
      <Route path="/about" element={<About />} />
      <Route path="/accept-terms" element={isAuthenticated ? <AcceptTerms /> : <Navigate to="/" />} />
      {/* ... (Other routes) */}
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <AuthenticatedRoutes />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </Router>
    </AuthProvider>
  );
}

export default App;
