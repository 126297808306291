import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { produce }from 'immer';
import 'react-toastify/dist/ReactToastify.css';
//import Chart from 'react-apexcharts';
//import { useSwipeable } from 'react-swipeable';
import SwipeableViews from 'react-swipeable-views';
import { format, formatISO, addDays, subDays } from 'date-fns'; // For date manipulation

// Profile component
function Profile() {
    const [currentDate, setCurrentDate] = useState(new Date()); // Initialize currentDate state with the current date
    const [profile, setProfile] = useState(() => {
        const isoDateString = formatISO(currentDate, { representation: 'date' }); // Calculate isoDateString based on currentDate
            return {
            name: '',
            birthdate: '',
            gender: 'Select...',
            fitnessGoals: { goals: '', milestones: '' },
            healthStats: { userId: 0, date: isoDateString, waterIntake: 0, sleepHours: 0, restingHeartRate: 0,  activeHeartRate: 0, systolicBP: 0, diastolicBP: 0 ,},
            activities: { workoutTypes: '', frequency: '', duration: '', intensity: '' },
            nutrition: { userId: 0, date: isoDateString, dietaryPreferences: '',  calories: 0, protein: 0, carbs: 0, fats: 0 , supplements: '' },
            measurements: {
                heightCm: 0,
                weightKg: 0,
            },
        };
    });

    const isoDateString = formatISO(currentDate, { representation: 'date' }); // Calculate isoDateString based on currentDate
    
    


    // Function to calculate age from birthdate
    const calculateAge = (birthdate) => {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    

    // Function to calculate BMI
    const calculateBMI = (heightCm, weightKg) => {
        if (heightCm > 0 && weightKg > 0) {
            const heightInMeters = heightCm / 100;
            return (weightKg / (heightInMeters ** 2)).toFixed(2);
        }
        return 0;
    };
    
    useEffect(() => {
        // Convert currentDate to ISO string for the query parameter
        const fetchProfileDate = formatISO(currentDate, { representation: 'date' });
        
        // Updated fetchProfile function to accept a date parameter correctly
        const fetchProfile = async () => {
            try {
                // Use template literals correctly to pass the selected date
                const response = await axios.post(`/api/profile?date=${fetchProfileDate}`, { withCredentials: true });
                const fetchedProfile = response.data;
    
                // Rest of the logic remains the same
                const formattedBirthdate = fetchedProfile.birthdate.split('T')[0];
                setProfile(prevProfile => ({
                    ...prevProfile,
                    name: fetchedProfile.name || '',
                    birthdate: formattedBirthdate || '',
                    gender: fetchedProfile.gender || 'Select...',
                    fitnessGoals: fetchedProfile.fitnessGoals || { goals: '', milestones: '' },
                    healthStats: { userId: 0, date: isoDateString, waterIntake: 0, sleepHours: 0, restingHeartRate: 0,  activeHeartRate: 0, systolicBP: 0, diastolicBP: 0 ,},
                    activities: fetchedProfile.activities || { workoutTypes: '', frequency: '', duration: '', intensity: '' },
                    nutrition: fetchedProfile.nutrition || {userId: 0, date: isoDateString, dietaryPreferences: '', calories: 0, protein: 0, carbs: 0, fats: 0, supplements: '' },
                    measurements: fetchedProfile.measurements || { heightCm: 0, weightKg: 0 },
                }));
                toast.success('Profile fetched successfully!');
            } catch (error) {
                console.error('Failed to fetch profile:', error);
                toast.error('Failed to fetch profile.');
            }
        };
    
        fetchProfile();
    }, [currentDate]);

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;
    
        setProfile(currentProfile => produce(currentProfile, draft => {
            // Split the name to access nested properties
            const keys = name.split(".");
            let current = draft;
            for (let i = 0; i < keys.length - 1; i++) {
                current = current[keys[i]];
            }
            // Update the value of the nested property
            current[keys[keys.length - 1]] = type === 'number' ? parseFloat(value) : value;
        }));
    };
    
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Prepare the payload with only allowed fields
        const updatePayload = {
            name: profile.name,
            birthdate: profile.birthdate,
            gender: profile.gender,
            fitnessGoals: profile.fitnessGoals,
            healthStats: profile.healthStats,
            activities: profile.activities,
            nutrition: profile.nutrition,
            measurements: profile.measurements,
            // Remove fields not expected by the backend or validate them if your backend has been updated
        };
        console.log("Submitting the following profile data:", updatePayload); // Debugging statement

        try {
            await axios.post('/api/update-profile', updatePayload, { withCredentials: true });
            toast.success('Profile updated successfully!');
        } catch (error) {
            console.error('Failed to update profile:', error.response?.data?.error || 'An unknown error occurred');
            toast.error(`Failed to update profile: ${error.response?.data?.error || 'An unknown error occurred'}`);
        }
    };
    
     // Adjust handleDateChange to correctly use setCurrentDate
     const handleDateChange = (delta) => {
        setCurrentDate(current => {
            const newDate = delta < 0 ? subDays(current, Math.abs(delta)) : addDays(current, delta);
            const isoDateString = formatISO(newDate, { representation: 'date' }); // Recalculate isoDateString
            // Update profile with the new isoDateString
            setProfile(prevProfile => ({
                ...prevProfile,
                healthStats: { ...prevProfile.healthStats, date: isoDateString},
                nutrition: { ...prevProfile.nutrition, date: isoDateString },
            }));
            return newDate;
        });
    };

    return (
        <div className="profile-container">
            <form onSubmit={(e) => e.preventDefault()}>
                {/* Dynamic "User Profile" Header */}
                <h1>{profile.name ? `${profile.name}'s Profile` : "User Profile"}</h1>
                
                {/* Non-changing profile fields */}
                <div className="static-profile-fields">
                    {/* Name */}
                    <label>Name:</label>
                    <input name="name" type="text" value={profile.name || ''} onChange={handleInputChange} />
                    {/* Birthdate */}
                    <label>Birthdate:</label>
                    <input name="birthdate" type="date" value={profile.birthdate || ''} onChange={handleInputChange} />
                    {/* Age */}
                    <label>Age:</label>
                    <input type="number" value={calculateAge(profile.birthdate)} readOnly />
                    {/* Gender */}
                    <label>Gender:</label>
                    <select name="gender" value={profile.gender} onChange={handleInputChange}>
                        <option value="Select...">Select...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div>
                    <h3>Fitness Goals</h3>
                    <label>Goals:</label>
                    <input type="text" name="fitnessGoals.goals" value={profile.fitnessGoals.goals || ''} onChange={handleInputChange} />
                    <label>Milestones:</label>
                    <input type="text" name="fitnessGoals.milestones" value={profile.fitnessGoals.milestones || ''} onChange={handleInputChange} />
                </div>
                <div>
                    <h3>Activities</h3>
                    <label>Workout Types:</label>
                    <input type="text" name="activities.workoutTypes" value={profile.activities.workoutTypes || ''} onChange={handleInputChange} />
                    <label>Frequency:</label>
                    <input type="text" name="activities.frequency" value={profile.activities.frequency || ''} onChange={handleInputChange} />
                    <label>Duration:</label>
                    <input type="text" name="activities.duration" value={profile.activities.duration || ''} onChange={handleInputChange} />
                    <label>Intensity:</label>
                    <input type="text" name="activities.intensity" value={profile.activities.intensity || ''} onChange={handleInputChange} />
                </div>
                <div>
                    <h3>Measurements</h3>
                    <label>Height (cm):</label>
                    <input type="number" name="measurements.heightCm" value={profile.measurements.heightCm || '0'} onChange={handleInputChange} />
                    <label>Weight (kg):</label>
                    <input type="number" name="measurements.weightKg" value={profile.measurements.weightKg || '0'} onChange={handleInputChange} />
                    <label>BMI:</label>
                    <input type="number" value={calculateBMI(profile.measurements.heightCm, profile.measurements.weightKg)} readOnly />
                </div>
                <div>
                    <h3>Health Stats Data for {format(currentDate, 'PPP')}</h3>
                    {/* Controls for changing the date */}
                    <button type="button" onClick={() => handleDateChange(-1)}>Previous Day</button>
                    <button type= "button" onClick={() => handleDateChange(1)}>Next Day</button>
                    {/* You might want to add a date picker here for quick navigation */}

                </div>

                {/* SwipeableViews for categories that require day-by-day tracking */}
                {/* Health Stats Swipeable View */}
                <SwipeableViews>
                            {/* Dynamically rendered content for each swipe view */}
                                <div>
                                    <h4>Health Stats</h4>
                                    <label>Water Intake (Oz):</label>
                                    <input
                                        type="number"
                                        name = "healthStats.waterIntake"
                                        value={profile.healthStats.waterIntake || ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Sleep Hours:</label>
                                    <input
                                        type="number"
                                        name = "healthStats.sleepHours"
                                        value={profile.healthStats.sleepHours|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Resting Heart Rate:</label>
                                    <input
                                        type="number"
                                        name = "healthStats.restingHeartRate"
                                        value={profile.healthStats.restingHeartRate|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Active Heart Rate:</label>
                                    <input
                                        type="number"
                                        name = "healthStats.activeHeartRate"
                                        value={profile.healthStats.activeHeartRate|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Systolic Blood Pressure:</label>
                                    <input
                                        type="number"
                                        name = "healthStats.systolicBP"
                                        value={profile.healthStats.systolicBP|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Diastolic Blood Pressure:</label>
                                    <input
                                        type="number"
                                        name = "healthStats.diastolicBP"
                                        value={profile.healthStats.diastolicBP|| ''}
                                        onChange= {handleInputChange}
                                    />
                                </div>
                </SwipeableViews>
                <div>
                <div>
                    <h3> Nutrition Data for {format(currentDate, 'PPP')}</h3>
                    {/* Controls for changing the date */}
                    <button type="button" onClick={() => handleDateChange(-1)}>Previous Day</button>
                    <button type="button" onClick={() => handleDateChange(1)}>Next Day</button>
                    {/* You might want to add a date picker here for quick navigation */}
                </div>
                {/* Nutrition Swipeable View */}
                <SwipeableViews>
                                <div>
                                    <h4>Nutrition</h4>
                                    <label>Meals (Track what you ate for the day):</label>
                                    <input
                                        type="text"
                                        name = "nutrition.dietaryPreferences"
                                        value={profile.nutrition.dietaryPreferences|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Calories:</label>
                                    <input
                                        type="number"
                                        name = "nutrition.calories"
                                        value={profile.nutrition.calories|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Protein (g):</label>
                                    <input
                                        type="number"
                                        name = "nutrition.protein"
                                        value={profile.nutrition.protein|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Carbs (g):</label>
                                    <input
                                        type="number"
                                        name = "nutrition.carbs"
                                        value={profile.nutrition.carbs|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Fats (g):</label>
                                    <input
                                        type="number"
                                        name = "nutrition.fats"
                                        value={profile.nutrition.fats|| ''}
                                        onChange= {handleInputChange}
                                    />
                                    <label>Supplements:</label>
                                    <input
                                        type="text"
                                        name = "nutrition.supplements"
                                        value={profile.nutrition.supplements|| ''}
                                        onChange= {handleInputChange}
                                    />
                                </div>
                </SwipeableViews>
                </div>
                <button type="submit" onClick={handleSubmit}>Save Profile</button>
            </form>
        </div>
    );
}

export default Profile;