import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
        try {
            const response = await axios.get('/api/check-auth', { withCredentials: true });
            setIsAuthenticated(true);
            setUser(response.data.user);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Expected when not logged in, handle gracefully
                setIsAuthenticated(false);
                setUser(null);
            } else {
                console.error('Error checking authentication:', error);
            }
        }
    };

    checkAuth();
}, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('/login', { username, password });
      if (response.status === 200) {
        setIsAuthenticated(true);
        setUser(response.data.user); // Assuming your login endpoint returns user data
      }
    } catch (error) {
      console.error('Login failed:', error);
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const logout = async () => {
    try {
      await axios.get('/logout');
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, setIsAuthenticated, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
