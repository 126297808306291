import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AcceptTerms() {
// States for document acceptance
const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);
const [waiverAccepted, setWaiverAccepted] = useState(false);

const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all terms are accepted
    if (!privacyPolicyAccepted || !termsOfUseAccepted || !waiverAccepted) {
      alert('You must accept all terms to proceed.');
      return;
    }
    console.log('Submitting acceptance...');
    console.log('Privacy Policy:', privacyPolicyAccepted);
    console.log('Terms of Use:', termsOfUseAccepted);
    console.log('Waiver:', waiverAccepted);
    // Submit acceptance to the server
    try {
        const response = await axios.post('/accept-terms', {
            privacyPolicyAccepted: true,
            termsOfUseAccepted: true,
            waiverAccepted: true,
          }, { withCredentials: true });
      if (response.status === 200) {
        navigate('/'); // Navigate to home or dashboard upon successful acceptance
      }
    } catch (error) {
      alert('Failed to accept terms. Please try again.');
    }
  };

  return (
    <div>
      <h2>Accept Terms</h2>
      {/* Consolidated legal documents and checkboxes */}
      <div className="compliance-documents">
        <div>
          <label>
            <input
              type="checkbox"
              checked={privacyPolicyAccepted}
              onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
            />
            I agree to the <a href="/documents/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={termsOfUseAccepted}
              onChange={(e) => setTermsOfUseAccepted(e.target.checked)}
            />
            I agree to the <a href="/documents/terms-of-use.html" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={waiverAccepted}
              onChange={(e) => setWaiverAccepted(e.target.checked)}
            />
            I have read and accept the <a href="/documents/waiver-of-liability.html" target="_blank" rel="noopener noreferrer">Waiver of Liability</a>.
          </label>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default AcceptTerms;
