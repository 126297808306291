import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';

const Workouts = () => {
    const [workouts, setWorkouts] = useState([]);

    const addWorkoutsToCalendar = async (userId, workoutData) => {
      const response = await axios.post('/api/workouts/log/add', {
        userId,
        workouts: workoutData
      });
    
      if (response.status === 200) {
        console.log("Workouts logged successfully");
        // Optionally, trigger a state update or a UI refresh to reflect the new workouts
      } else {
        console.error("Failed to log workouts");
      }
    };
    
    useEffect(() => {
        // Fetch workouts and update state
        axios.get('/api/workouts').then(response => {
            setWorkouts(response.data.map(workout => ({
                title: workout.name,
                start: workout.date
            })));
        }).catch(error => console.error('Failed to fetch workouts', error));
    }, []);

    const handleDateClick = (arg) => {
        // Implement logic to display workout details for the clicked date
        console.log('Date clicked', arg.dateStr);
        // Possible redirection to a detailed view or opening a modal
    };

    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={workouts}
            dateClick={handleDateClick}
        />
    );
};

export default Workouts;
