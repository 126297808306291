import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './app.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Home() {
    const [threadId, setThreadId] = useState(null);
    const [chatLog, setChatLog] = useState([]);
    const [message, setMessage] = useState(''); // Added state for managing the message input
    const inputRef = useRef(null);
    const [initialQuestionSent, setInitialQuestionSent] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();


    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            inputRef.current.focus();
        }
            
        // Function to start the conversation with the assistant
        const startDialogue = async () => {
            try {
                const response = await axios.post('/api/start-conversation');
                setThreadId(response.data.threadId);
            } catch (error) {
                console.error("Error starting dialogue:", error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        startDialogue();
        // Function to send the initial question to the assistant
        const sendInitialQuestionToAssistant = async () => {
            const initialMessage = "initiate conversation"; // A predefined message to trigger the assistant's first question
            await sendMessage(initialMessage);
        };

        if (threadId && !initialQuestionSent) {
            sendInitialQuestionToAssistant().then(() => {
                setInitialQuestionSent(true);
            });
        }
    }, [threadId, initialQuestionSent, navigate]);

    const sendMessage = useCallback(async (message, displayInUI = true) => {
        if (!message.trim()) return;
    
        try {
                await axios.post('/api/send-message', {
                message,
                threadId,
                display: displayInUI, // Include a display flag when sending the message
            });
    
            // Only update the chat log if the message is intended for UI display
            if (displayInUI) {
                setChatLog(log => [...log, { sender: 'user', text: message }]);
            }
    
            getResponses();
        } catch (error) {
            console.error("Error sending message:", error);
        }
    }, [threadId, navigate]);
    

    const getResponses = async () => {
        if (!threadId) return;
        try {
            const response = await axios.get(`/api/get-responses?threadId=${threadId}`);
            const messages = response.data.messages;
            if (Array.isArray(messages)) {
                let newChatLog = messages.reduce((acc, msg) => {
                    // Exclude system messages, profile data messages, and 'initiate conversation' messages
                    if (msg.role !== 'system' && !isProfileDataMessage(msg) && !isInitiateConversationMessage(msg)) {
                        acc.push({
                            sender: msg.role === 'assistant' ? 'assistant' : 'user',
                            text: msg.content[0]?.text?.value
                        });
                    }
                    return acc;
                }, []);
    
                setChatLog(newChatLog);
            }
        } catch (error) {
            console.error("Error getting responses:", error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };
    
    // Helper function to determine if the message is profile data
    const isProfileDataMessage = (msg) => {
        return msg.content[0]?.text?.value.startsWith("User profile data:");
    };
    
    // Helper function to determine if the message is 'initiate conversation'
    const isInitiateConversationMessage = (msg) => {
        return msg.content[0]?.text?.value === 'initiate conversation';
    };
    

    const promptUserConfirmation = (assistantOutput) => {
        const intent = assistantOutput.includes("profile update") ? 'profile-update' : 
                       (assistantOutput.includes("workout schedule") ? 'workout-schedule' : null);
    
        const AcceptButton = ({ closeToast }) => (
            <button onClick={() => {
                confirmIntent(intent, assistantOutput);
                closeToast();
            }}>
                Accept
            </button>
        );
    
        const DeclineButton = ({ closeToast }) => (
            <button onClick={closeToast}>Decline</button>
        );
    
        if (intent) {
            toast.info(({ closeToast }) => (
                <div>
                    Togofit: I detected that you want to {intent.replace('-', ' ')}. Do you want to proceed?
                    <div>
                        <AcceptButton closeToast={closeToast} /> 
                        <DeclineButton closeToast={closeToast} />
                    </div>
                </div>
            ), {
                position: "top-center",
                autoClose: false,
                closeOnClick: false,
                draggable: true,
                toastId: `confirm-intent-${intent}`
            });
        }
    };
    const confirmIntent = async (intent, assistantOutput) => {
        try {
            // Send the raw output from the assistant to the backend
            const response = await axios.post('/api/confirm-intent', { intent, data: assistantOutput });
            toast.dismiss(`confirm-intent-${intent}`); // Dismiss the confirmation toast
            // Handle response, such as updating UI based on backend processing
            if (response.data && response.data.message) {
                toast.success(response.data.message);
            } else {
                throw new Error('Unknown response from server.');
            }
        } catch (error) {
            console.error(`Error confirming intent ${intent}:`, error);
            toast.dismiss(`confirm-intent-${intent}`); // Dismiss the confirmation toast
            toast.error(error.response?.data?.error || 'Failed to confirm intent.');
        }
    };
       

    const handleSendMessage = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(message);
            setMessage('');
        }
    };

    const handleTextChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendButtonClick = () => {
        if (!message.trim()) return; // Do not send empty messages
        sendMessage(message);
        setMessage(''); // Clear the message input after sending
    };

    const weightPlateIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#88B04B" width="50px" height="50px">
            <circle cx="100" cy="100" r="95" stroke="black" strokeWidth="5" fill="none"/>
            <circle cx="100" cy="100" r="75" stroke="black" strokeWidth="5" fill="none"/>
            <path d="M 50,100 m -4,0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0" fill="black"/>
            <path d="M 150,100 m -4,0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0" fill="black"/>
            <path d="M 100,50 m 0,-4 a 4,4 0 1,0 0,8 a 4,4 0 1,0 0,-8" fill="black"/>
            <path d="M 100,150 m 0,-4 a 4,4 0 1,0 0,8 a 4,4 0 1,0 0,-8" fill="black"/>
            <text x="100" y="125" textAnchor="middle" stroke="#ffffff" strokeWidth="1px" fontSize="20" fill="#ffffff">Send</text>
        </svg>
    );

    return (
        <div className="home-container container">
            <h1>TOGO FITNESS</h1>
            <div className="chat-thread">
                {chatLog.map((message, index) => (
                    <div key={index} className={`chat-bubble ${message.sender}`}>
                        <div className="message-text" dangerouslySetInnerHTML={{ __html: message.text }} />
                    </div>
                ))}
            </div>
            <div className="chat-input-area">
            <textarea
                ref={inputRef}
                className="chat-input"
                placeholder="Type a message..."
                value={message}
                onChange={handleTextChange}
                onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage(e)}
            />
            <button className="send-button" onClick={handleSendButtonClick}>
                {weightPlateIcon}
            </button>
        </div>
        <ToastContainer />
    </div>
);
}

export default Home;