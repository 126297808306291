import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import styles from './Navbar.module.css';

function Navbar() {
    const { isAuthenticated, setIsAuthenticated, setUser } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Assuming you have a backend endpoint to handle logout
            await axios.post('/logout', {}, { withCredentials: true });
            setIsAuthenticated(false);
            setUser(null);
            navigate('/login'); // Redirect to login page after logout
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <nav className={styles.navbar}>
            <img src="/togofitlogo.png" alt="TOGO FIT" style={{ height: '60px' }} /> {/* Adjust the height as needed */}
            <ul>
                <li><Link to="/">Home</Link></li>
                {isAuthenticated ? (
                    <>
                        <li><Link to="/profile">Profile</Link></li>
                        <li><Link to="/workouts">Workouts</Link></li>
                        {/* Removed the logout button from here */}
                    </>
                ) : (
                    <>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/register">Register</Link></li>
                    </>
                )}
                <li><Link to="/about">About</Link></li> {/* Ensures "About" is always visible */}
                {isAuthenticated && (
                    <li style={{ marginLeft: 'auto' }}>
                        <Link to="/" onClick={handleLogout} className={styles.logoutLink}>Logout</Link> {/* Logout is now a link */}
                    </li>
                )}
            </ul>
        </nav>
    );

}
    
export default Navbar;
