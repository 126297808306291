import React from 'react';

function About() {
    return (
        <div className="about-container">
            <h1>About ToGoFit</h1>
            <p>ToGoFit is a personalized fitness assistant designed to help you achieve your fitness goals. Whether you're looking to improve your strength, endurance, or overall health, ToGoFit offers tailored workout and nutrition plans to guide you on your journey.</p>
            
            <h2>How to Use ToGoFit</h2>
            <ol>
                <li>Register and create your profile, detailing your fitness goals and preferences.</li>
                <li>Receive personalized workout and nutrition plans based on your profile.</li>
                <li>Track your progress and adjust your goals as needed.</li>
            </ol>

            {/* Compliance Documents Links */}
            <div className="compliance-documents">
                <h2>Our Policies</h2>
                <p>Review our policies to understand how we handle your information:</p>
                <ul>
                    <li><a href="/documents/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="/documents/terms-of-use.html" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><a href="/documents/waiver-of-liability.html" target="_blank" rel="noopener noreferrer">Waiver of Liability</a></li>
                </ul>
            </div>

            <h2>Contact Us</h2>
            <p>If you have any questions or feedback, please don't hesitate to reach out to us at jordon@jormado.com.</p>

            <h2>What to Expect Next</h2>
            <p>We're constantly working to improve ToGoFit. Upcoming features include interactive saved profile information, advanced performance tracking, and more. Stay tuned!</p>
        </div>
    );
}

export default About;
